import React from "react";

export default () => (
	<div className="bg-grid-raster-dark-center">
		<div className="text-white site-padding-vertical">
			<div className="edge align-baseline  trim fit-down">
				<h1>Typography.</h1>
				<p className="text-preset-large-intro max-char-45">One special thing about the SpaceFramework, is
					typography. By defining the <dfn>x-height</dfn> and <dfn>cap-height</dfn> of each font we can create endless
					alignment possibilities. This gives you a free hand in realizing your designs exactly how it
					originally was intended.</p>

				<section>
					<h2>Vertical alignment</h2>
					<p className="text-preset-medium-intro max-char-45">When you apply vertical alignment on your text you
						can
						decide how your text is vertical aligned and create a nice vertical rhythm / pattern.</p>
					<p>A reason you want to do this is to have
						exactly the right amount of spacing between each heading, paragraph, images e.t.c. </p>

					<div className="grid medium-grid-2 large-grid-4 block-margin-vertical">
						<div className="align-reset trim bg-lines">
							<h3>Default text</h3>
							<p>Compare the other alignment types with this default one. This is how the browser renders
								your
								text by default. </p>
						</div>
						<div className="align-baseline trim bg-lines">
							<h3>Align baseline</h3>
							<p>With <dfn>baseline alignment</dfn> you focus on aligning a text on the bottom of the
								character.
								This is called the
								baseline.</p>
						</div>
						<div className="align-capline trim bg-lines">
							<h3>Align capline</h3>
							<p>With <dfn>capline alignment</dfn> you focus on aligning a text on the top of a uppercase
								character. This is
								called the capline</p>
						</div>
						<div className="align-median trim bg-lines">
							<h3>Align median</h3>
							<p>With <dfn>median alignment</dfn> you focus on aligning a text on the top of a lowercase
								character. This is
								called the median</p>
						</div>
					</div>
				</section>

				<section>
					<h2>Fit</h2>
					<p className="text-preset-medium-intro max-char-45"><dfn>Fit</dfn> removes whitespace from the first
						text
						line of the first element. This can be used to have text exactly aligned to your padding.</p>
					<p>Fit has to be combined with align-baseline</p>
					<div className="grid medium-grid-2 large-grid-4 align-items-start block-margin-vertical">
						<div className="align-reset trim bg-fit">
							<h3>Default text</h3>
							<p>Compare the other alignment types with this default one. This is how the browser renders
								your text by default. Notice the space between the text and the blue marked padding. We want
								to get rid of this!</p>
						</div>
						<div className="align-baseline trim fit bg-fit">
							<h3>Fit</h3>
							<p>Default <dfn>fit</dfn> removes all white space at the first line of the first element. No
								longer
								your text will be aligned on the virtual grid. But your paddings are exact.</p>
						</div>
						<div className="align-baseline trim fit-up bg-fit">
							<h3>Fit up</h3>
							<p><dfn>Fit up</dfn> removes all white space at the first line of the first element but
								makes
								sure
								everything stays on the virtual grid, it will go up to the next virtual line</p>

						</div>
						<div className="align-baseline trim fit-down bg-fit">
							<h3>Fit down</h3>
							<p><dfn>Fit down</dfn> removes all white space at the first line of the first element but
								makes
								sure
								everything stays on the virtual grid, it will go down to the next virtual line</p>
						</div>
					</div>
				</section>

				<section>
					<h2>Trim</h2>
					<p className="text-preset-medium-intro max-char-45">
						By using <dfn>trim</dfn> you no longer need to remove margins manually from the first- and
						last-child. <dfn>Trim</dfn> does it all.
					</p>
					<p><dfn>Trim</dfn> makes the way free to build modular components without the need to worry about
						those
						extra margins. They simply get removed. This way you can just add margins at the top and bottom
						of
						each component so they always end up nicely in a modular way and use margins the way it was
						intended
					</p>
					<div className="grid medium-grid-2 large-grid-4 align-reset align-items-start block-margin-vertical">
						<div className="bg-margin">
							<div className="bg-grid-raster-dark trim-example">
								<h3>Default text</h3>
								<p>This is a default text, notice the default margins at the top and the bottom.</p>
							</div>
						</div>
						<div className="bg-margin">
							<div className="bg-grid-raster-dark trim trim-example">
								<h3>Trim</h3>
								<p>With <dfn>trim</dfn> the top- and the bottom-margin are removed from the first- and
									last-child</p>
							</div>
						</div>
						<div className="bg-margin">
							<div className="trim-top bg-grid-raster-dark trim-example">
								<h3>Trim top</h3>
								<p>With <dfn>trim-top</dfn> the top margin of the first child is removed.</p>
							</div>
						</div>
						<div className="bg-margin">
							<div className="bg-grid-raster-dark trim-bottom trim-example">
								<h3>Trim bottom</h3>
								<p>With <dfn>trim-bottom</dfn> the bottom margin of the last child is removed.</p>
							</div>
						</div>
					</div>
				</section>

				<section>
					<h2>Text presets</h2>
					<p className="text-preset-medium-intro max-char-45">
						By centralizing all typography at one place, you never have to maintain text styles at different
						places or write down the same CSS code all over again. All you need to do is choose the right
						text
						style at the right place.
					</p>
					<p>You can use classes or the SCSS mixin to apply the right text style. This way you can say for
						example:</p>
					<ul>
						<li>i want to apply the <dfn>heading-1</dfn> text-preset too all h1 elements</li>
						<li> and i want to apply the <dfn>link</dfn> text-preset to all a elements.</li>
						<li>This specific h1 element need to have <dfn>hero</dfn> text-preset</li>
					</ul>
					<p>Especially when working together with Designers using <dfn>text-presets</dfn> are very useful.
						This
						is because you can match the text-presets exactly with the styles created in for example Sketch
						or
						AdobeXD. Resulting in a workflow where you can synchronize front-end with the design at all
						times.
					</p>
				</section>
			</div>
		</div>
	</div>
);