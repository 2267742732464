import React from "react"

import Hero from "../components/hero"
import Typography from "../components/typography";
import Grid from "../components/grid"
import Space from "../components/space"
import Layout from "../components/layout"
//import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
	<Layout>
		<SEO title="Space Framework"/>
		<Hero />
		<Typography />
		<Space />
		<Grid />


	</Layout>
);

export default IndexPage
