import React from "react";

const Grid = () => (
	<div className="bg-grid-raster-white-center">
		<div className="edge align-baseline">
			<div className="bg-layout-raster site-padding-vertical grid-examples trim fit-down">
				<h1>Grid.</h1>
				<p className="text-preset-large-intro max-char-45">Grid. Simply. Easy.</p>
				<p>The SpaceFramework grid is made with mobile development in mind. It adds auto gap space between the
					columns both horizontal and vertical.</p>
				<p>Grid comes with SpaceFramework 1.2</p>

				<h2>Grid Columns</h2>
				<p className="text-preset-medium-intro max-char-45">Grid columns exist by default out of 12 columns</p>

				<h3>Span</h3>
				<div className="grid grid-columns block-margin-vertical">
					<div className="span-12">span-12</div>
					<div className="span-1">span-1</div>
					<div className="span-11">span-11</div>
					<div className="span-2">span-2</div>
					<div className="span-10">span-10</div>
					<div className="span-3">span-3</div>
					<div className="span-9">span-9</div>
					<div className="span-4">span-4</div>
					<div className="span-8">span-8</div>
					<div className="span-5">span-5</div>
					<div className="span-7">span-7</div>
					<div className="span-6">span-6</div>
					<div className="span-6">span-6</div>
				</div>

				<h3>Row span</h3>
				<div className="grid grid-4 block-margin-vertical">
					<div className="span-2">span-2</div>
					<div>auto</div>
					<div className="row-span-2">row-span-2</div>
					<div className="row-span-2">row-span-2</div>
					<div className="span-2">span-2</div>
					<div>auto</div>
					<div className="span-2">span-2</div>
				</div>

				<h3>Start</h3>
				<div className="grid grid-columns block-margin-vertical">
					<div className="start-1 span-12">start-1</div>
					<div className="start-2 span-11">start-2</div>
					<div className="start-3 span-10">start-3</div>
					<div className="start-4 span-9">start-4</div>
					<div className="start-5 span-8">start-5</div>
					<div className="start-6 span-7">start-6</div>
					<div className="start-7 span-6">start-7</div>
					<div className="start-8 span-5">start-8</div>
					<div className="start-9 span-4">start-9</div>
					<div className="start-10 span-3">start-10</div>
					<div className="start-11 span-2">start-11</div>
					<div className="start-12 span-1">start-12</div>
				</div>
				<h2>Grid-[]</h2>
				<h3>Grid-4</h3>
				<div className="grid grid-4 block-margin-vertical">
					<div>auto</div>
					<div>auto</div>
					<div>auto</div>
					<div>auto</div>
					<div>auto</div>
					<div>auto</div>
					<div>auto</div>
					<div>auto</div>
					<div>auto</div>
					<div>auto</div>
					<div>auto</div>
					<div>auto</div>
				</div>
				<h3>Grid-3</h3>
				<div className="grid grid-3 block-margin-vertical">
					<div>auto</div>
					<div>auto</div>
					<div>auto</div>
					<div>auto</div>
					<div>auto</div>
					<div>auto</div>
					<div>auto</div>
					<div>auto</div>
					<div>auto</div>
				</div>
				<h3>Grid-2</h3>
				<div className="grid grid-2 block-margin-vertical">
					<div>auto</div>
					<div>auto</div>
					<div>auto</div>
					<div>auto</div>
					<div>auto</div>
					<div>auto</div>
				</div>
				<h3>Grid-1</h3>
				<div className="grid grid-1 block-margin-vertical">
					<div>auto</div>
					<div>auto</div>
					<div>auto</div>

				</div>

				<h2>Order</h2>
				<div className="grid grid-columns">
					<div className="span-5 order-3 box">order-3 (1th child)</div>
					<div className="span-2 order-2 box">order-2 (2nd child)</div>
					<div className="span-3 order-1 box">order-1 (3rd child)</div>
				</div>

				<h2>Gap</h2>

				<h3>gap-0</h3>

				<div className="grid grid-columns gap-0 block-margin-vertical">
					<div className="span-4">span-4</div>
					<div className="span-4">span-4</div>
					<div className="span-4">span-4</div>
					<div className="span-4">span-4</div>
					<div className="span-4">span-4</div>
					<div className="span-4">span-4</div>
				</div>

				<h3>gap-1</h3>
				<div className="grid grid-columns gap-1 block-margin-vertical">
					<div className="span-4">span-4</div>
					<div className="span-4">span-4</div>
					<div className="span-4">span-4</div>
					<div className="span-4">span-4</div>
					<div className="span-4">span-4</div>
					<div className="span-4">span-4</div>
				</div>

				<h3>gap-2</h3>
				<div className="grid grid-columns gap-2 block-margin-vertical">
					<div className="span-4">span-4</div>
					<div className="span-4">span-4</div>
					<div className="span-4">span-4</div>
					<div className="span-4">span-4</div>
					<div className="span-4">span-4</div>
					<div className="span-4">span-4</div>
				</div>

				<h3>row-gap-0</h3>
				<div className="grid grid-columns row-gap-0 block-margin-vertical">
					<div className="span-4">span-4</div>
					<div className="span-4">span-4</div>
					<div className="span-4">span-4</div>
					<div className="span-4">span-4</div>
					<div className="span-4">span-4</div>
					<div className="span-4">span-4</div>
				</div>

				<h3>col-gap-0</h3>
				<div className="grid grid-columns col-gap-0 block-margin-vertical">
					<div className="span-4">span-4</div>
					<div className="span-4">span-4</div>
					<div className="span-4">span-4</div>
					<div className="span-4">span-4</div>
					<div className="span-4">span-4</div>
					<div className="span-4">span-4</div>
				</div>

			</div>
		</div>
	</div>
);

export default Grid;