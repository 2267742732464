import React from "react"

export default () => (
	<>
		<div className="front-page">
			<div className="edge">
				<div className="hero align-baseline trim">
					<div className="logo-large">
						<div className="logo-large__border"/>
						<div className="logo-large__center  trim fit-up">
							<h1 className="text-preset-hero">Space<br/>Framework</h1>
						</div>
						<div className="logo-large__border logo-large__border--right"/>
						<span className="logo-large__version">V1.2.0</span>
					</div>
					{/*<h1>Gives <span className="text-red">love</span> <br/>to design.</h1>*/}
					<div className="trim fit-up">
						<p className="text-preset-large">A powerful responsive SCSS framework<br/> build with <strong>typography
							standards</strong> in mind.</p>
						<p>Do you want 100% control over every pixel you place? The SpaceFramework is the only front-end
							framework
							on the world wide web at which you can align every single pixel perfectly on a virtual raster
							grid.</p>
						<p>
							✓ <strong>Perfectly aligned.<br/>
							✓ No components. Ever. <br/>
							✓ Only awesome utilities and raw power!</strong>
						</p>
					</div>
					<div className="buttons">
						<a className="button button--primary button--center" target="_blank" rel="noreferrer"
						   href="https://docs.spaceframework.dev">Read documentation</a>
						<a className="button button--secondary button--center" target="_blank" rel="noreferrer"
						   href="https://github.com/HarwinBorger/SpaceFramework">Visit Github</a>
					</div>

				</div>
			</div>
		</div>
	</>
)
